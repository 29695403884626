<template>
  <div class="version-mismatch">
    <div class="exclamation-mark-icon">
      <exclamationMarkIcon />
    </div>

    <h1>{{ $t('version_mismatch.content.title') }}</h1>

    <p class="checkout-description">
      {{ $t('version_mismatch.content.description') }}
    </p>
  </div>
</template>

<script>
import exclamationMarkIcon from '@/assets/images/icons/exclamation-mark.svg';

export default {
  name: 'KioskVersionMismatch',
  components: {
    exclamationMarkIcon,
  },
};
</script>

<style lang="scss">
  .version-mismatch {
    text-align: center;
  }

  .exclamation-mark-icon {
    margin-bottom: 2rem;

    svg {
      width: 10rem;
    }
  }
</style>
